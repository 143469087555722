.Vendors-panel-container{
    background: #ffffff !important;
    box-shadow: 0px 4px 24px 0px #E0F0FF !important;
    border-radius: 8px !important;
    .Vendor-search{
        background: #ffffff !important;
        border: 1px solid #A2B9C9;
        border-radius: 5px !important;
        margin: 0px !important;
        input{
        width: 100% !important;
        border-radius: 5px !important;
        }
    }
    padding: 6px 15px 15px 3px;
    .Vendors-side-panel{
        flex-grow: 1;
        // max-width: 198px;
        width: 100%;
        background: #EDF6FF !important;
        padding: 11px 12px;
        max-height: 100%;
        .Vendors-side-panel-list{
            padding: 13px 23px 12px 18px;
            overflow: auto;
            .Vendors-side-panel-list-item{
                padding-bottom: 10px;
                width: 100%;
                height: 65px;
                // box-shadow: 0px 3px 0px 0px #93ABEB !important;
                border-radius: 4px !important;
                img{
                    max-height:39px; 
                    width: 38px;
                    height: auto;
                    margin-top: auto;
                    margin-bottom: auto;
                }
                .Vendors-side-panel-list-item-card{
                    border: 1px solid #C9E2FB !important;
                    padding: 13px 12px !important;
                    height:38px !important;
                    display: flex;
                }

            }
            .Vendors-side-panel-list-item.active{
                .Vendors-side-panel-list-item-card{
                    border: 1px solid #0A73C1 !important;
                }
            }
        }
    }
    .Vendors-main-panel{
        margin: 26px 23px;
        padding:21px 20px 16px 16px;
        flex-grow: 1;
        border-radius: 8px;
        box-shadow: 0px 4px 24px 0px #E0F0FF !important;

    }
}
.Vendor-table{
    thead{

        th{
            padding: 16px 16px !important;
            background: #85949F !important;
            color:#ffffff !important;
        }
    }
    tbody{
        td{
            padding: 10px 16px !important;
        }
    }
    tr{
        border-bottom:  1px solid #D9E2EA;
    }
    .border-right{
        border-right:  1px solid #D9E2EA;

    }
}