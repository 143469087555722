.headerSection {
    background-color: #05141F;
    padding: 9px 0;
    border-radius: 4px;

}
.headerSectionWhite {
    background-color: #ffffff;
    padding: 9px 0;
    border-radius: 4px;

}
.cardHeading {
    font-size: 15px !important;
     font-weight: 600 !important;
    color: #fff !important;
    padding-left: 15px; 
    position: relative;
    &:before {
        content: '';
        width: 3px;
        height: 20px;
        background: #465A68; 
        left: 0;
        top:50%;
        transform: translateY(-50%);
        border-radius: 10px;
        position: absolute;
    } 
}

.cardHeadingWhite {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #05141F !important;
    padding-left: 15px; 
    position: relative;
    &:before {
        content: '';
        width: 3px;
        height: 20px;
        // background: #465A68; 
        left: 0;
        top:50%;
        transform: translateY(-50%);
        border-radius: 10px;
        position: absolute;
    } 
}
.homeCard1 {
    box-shadow: 0px 3px 0px 0px #93ABEB !important;
    border-radius: 4px !important;
    border:1px solid #C9E2FB;
    padding: 15px 10px !important;
}
.active{
    .homeCard1 {
        box-shadow: 0px 3px 0px 0px #0A73C1  !important;
        border-radius: 4px !important;
        border:1px solid #0A73C1 ;
        padding: 15px 10px !important;
    }
    
}
.homeCard2 {
    box-shadow: 0px 3px 0px 0px #E7D42A !important;
    border-radius: 4px !important;
    border:1px solid #EEE47F;
    padding: 15px 10px !important;
}

.homeCard3 {
    box-shadow: 0px 3px 0px 0px #60C1A4 !important;
    border-radius: 4px !important;
    border:1px solid #84E3B5;
    padding: 15px 10px !important;
}

.homeCard4 {
    box-shadow: 0px 3px 0px 0px #F28A8A !important;
    border-radius: 4px !important;
    border:1px solid #FCA28F;
    padding: 15px 10px !important;
}

.homeCard5 {
    box-shadow: 0px 3px 0px 0px #b65ff4 !important;
    border-radius: 4px !important;
    border:1px solid #b65ff4;
    padding: 15px 10px !important;
}

.cardTitle{
    font-size:13px;
    font-weight:300;
    color:#05141F;
    margin-bottom:10px;
    line-height:21px;
}
.cardValue{
     font-size:26px;
     font-weight:600;
     color:#465A68;
     line-height:32px;
}
 