@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

* {
    padding: 0;
    margin: 0;
    background-size: border-box;
    font-family: "KiaSignatureLight" !important;
}

body, 
p { 
    font-family: "KiaSignatureLight" !important; 
    font-weight: 400 !important;
    font-size: 14px;
}
h6,
h5,
h4,
h3,
h2,
h1{
    font-family: "KiaSignatureRegular" !important;
}

body {
    background-color: #f9fcff !important;
    overflow-y: auto !important;
}

// scrollbar
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background-color: #F2F6FC;
}

::-webkit-scrollbar-thumb {
    background: #aaaaaa;
    border-radius: 25px;
}

.css-4pb9x1-MuiBackdrop-root-MuiModal-backdrop {
    background-color: rgba(5, 20, 31, 0.9) !important;
    // backdrop-filter: blur(10px);
}
.headerNotificationBadge{
   .MuiBadge-badge{
    background-color: rgba(245, 42, 42, 1) !important;
    font-size: 6px;
    height: 6px;
    padding: 0;
    min-width: 6px;
    right: 3px;
    top: 4px;
    color: rgba(245, 42, 42, 1) !important;
} 
}

input, .MuiInputBase-formControl{
    background-color: #ffff ;
}
 fieldset{
    border-color: #87A8C1 !important;
}


.MuiDataGrid-columnHeaderTitleContainerContent{
    min-width: max-content;
    // flex-grow: 1;
    .MuiDataGrid-columnHeaderTitle{
        min-width: max-content;
    }
}